@use "./functions" as *;
@use "./variables" as *;

.container {
    height: fit-content;
    max-width: $width;
    width: 100%;
    margin: 0 auto;

    @include break($width + 40) {
        padding: 0 40px;
    }

    @include break(991px) {
        padding: 0 20px;
    }

    @include break(768px) {
        padding: 0 20px;
    }

    @include break(540px) {
        padding: 0 15px;
    }

    @include break(460px) {
        padding: 0 10px;
    }
}

.no-content {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    line-height: 1.75rem;
    color: #5d6776;
    word-wrap: break-word;
    margin: 2rem 0;
    font-size: 1.15rem;
    pointer-events: none;
}

.bold {
    font-weight: bold;
}

.btn {
    display: inline-block;
    padding: 8px 30px;
    cursor: pointer;
    color: $white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    text-decoration: none;

    &.btn-primary {
        background-color: $red;
        color: $white;
        font-weight: 600;
    }

    &.rounded-full {
        font-size: 0.625rem;
        font-weight: 600;
        border-radius: 20px;

        fa-icon {
            padding-left: 14px;
        }
    }

    &.btn-black {
        background-color: #000;
        color: $white;
    }

    &.btn-load-more {
        background-color: #eee;
        color: $black;
        transition: all 0.2s ease;

        &:hover {
            transition: all 0.2s ease;
            background-color: #e5e7eb;
        }
    }

    &.btn-trans {
        background-color: transparent;
        color: $grey;
    }

    &.border-radius-small {
        border-radius: 0.35rem;
    }

    @include break(768px) {
        padding: 0.5rem 1rem;
        a {
            font-size: 14px;
        }
    }

    @include break(540px) {
        padding: 0.35rem 0.75rem;
    }
}

/* Backgrounds & colored buttons */
.btn-primary {
    background-color: $red;
    color: $white;
}

.btn-secondary {
    background-color: var(--secondary-color);
    color: $white;
}

.bg-gray {
    background-color: $light-grey;
}

.bg-light {
    background-color: $bg-light;
    display: block;
}

.btn-light {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
    color: $black;
}

.tooltip {
    position: relative;
    display: inline-block;

    &.unauthenticated {
        cursor: default;
        pointer-events: none;
    }

    .tooltiptext {
        visibility: hidden;
        width: fit-content;
        min-width: 100px;
        max-width: 200px;
        background-color: $black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 0.5rem;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
    }
}

.p-tooltip {
    padding: 0.85rem 0.5rem 0.5rem;

    .p-tooltip-text {
        padding: 0.3rem 0.5rem;
    }
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip-clipboard {
    position: relative;
    display: inline-block;

    .tooltiptext-clipboard {
        visibility: hidden;
        width: fit-content;
        max-width: 200px;
        min-width: 100px;
        background-color: $black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 0.5rem;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        visibility: visible;
    }
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

/* Margin */
.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 1.5rem;
}

.mt-3 {
    margin-top: 2rem;
}

.mt-5 {
    margin-top: 4rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 1.5rem;
}

.mb-3 {
    margin-bottom: 2rem;
}

.mb-4 {
    margin-bottom: 3rem;
}

.mb-5 {
    margin-bottom: 4rem;
}

.mb-6 {
    margin-bottom: 5rem;
}

.me-1 {
    margin-right: 1rem;
}

.ms-1 {
    margin-left: 1rem;
}

.my-2 {
    margin: 1.5rem 0;
}

/* Padding */
.py-2 {
    padding: 1.5rem 0;
}

.p-2 {
    padding: 1.5rem;
}

// Forms
.form-field {
    padding: 0.75rem;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    outline: none;
    border: none;
}

.form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    &.row {
        flex-direction: row;
        align-items: center;

        label {
            margin-bottom: 0;
        }
    }

    label {
        font-size: 16px;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }

    input, textarea {
        padding: 0.75rem;
        border-radius: 5px;
        font-family: 'Inter', sans-serif;
        outline: none;
        border: 1px solid rgb(228, 231, 234);

        &[type="checkbox"] {
            margin-right: 1rem;
            cursor: pointer;
        }
    }
}

.form-card {
    border-radius: 0.5rem;
    width: 28rem;
    max-width: 100%;

    @media only screen and (max-width: 700px) {
        width: 25rem;
    }

    @media only screen and (max-width: 500px) {
        width: 20rem;
    }
}


.light-color {
    color: $grey;
}

.hidden,
[hidden] {
    display: none !important;
}

button, input[type="submit"], input[type="reset"] {
    &:not(.p-component) {
        background: none;
        color: inherit;
        border: none;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
